import styled from 'styled-components'
import firstImage from '../data/Images/aeroplaneBack.png'

export const PopularTripContainer = styled.div`
  margin: 5% 0;
  background-image: url(${firstImage});
  background-size: contain;
  background-position: -30px;
  background-repeat: no-repeat;
  margin-bottom: 2%;
  font-size: 5.2vw;
  position: relative;
`

export const PopularButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PopularTripHeading = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${(props) => props.margin};
  padding: 3% 0;
  flex-wrap: wrap;
  font-size: ${(props) => props.fontSize};
`

export const PopularTripContent = styled.div`
  display: flex;
  overflow-x: auto;

  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  gap: 20px;
  scrollbar-width: none;
  overflow-y: hidden;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`

export const PopularHeadingLeft = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 70%;
  font-weight: 600;
  line-height: 66px;
  text-align: left;
`

export const PopularHeadingRight = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 30%;

  .left {
    background-color: white;
    color: black;
    border: black 1px solid;
  }
`

export const PopularButton = styled.button`
  font-size: 2vw;
  padding: 15px;
  cursor: pointer;
  background-color: #000000;
  border-radius: 50px;
  font-weight: 700;
  border: none;
  color: rgb(255, 255, 255);
`

export const ArrowButtonContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ArrowButton = styled.button`
  background: #2d3134;
  color: #faf8ed;
  border: none;
  border-radius: 50%;
  width: 3%;
  aspect-ratio: 1;
  margin: 0 0.5%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2vw;
  &.left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &.right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`
